<template>
    <div
        :style="{'background-color': background_color}"
        class="printer-wrapper w-full"
        :data-print-ready="true"
    >
        <v-style>
            .printer-wrapper a { color: {{ primary_color }}; }
        </v-style>
        <div class="max-w-5xl pt-4 px-10 mx-auto">
            <intro
                class="intro-preview"
                :title="title"
                :description="description"
                :title-font-name="title_font_name"
                :title-font-weight="title_font_weight"
                :answer-font-name="answer_font_name"
                :answer-font-weight="answer_font_weight"
                :primary-color="primary_color"
                :text-color="text_color"
                :is-filling-time-visible="is_filling_time_visible"
                :elements="editableElements"
                :logo-url="logo_file_url"
            />
            <div
                v-for="(element, i) in editableElements"
                :key="element.guid"
                style="break-inside: avoid; display: table;"
            >
                <survey-element
                    :element="element"
                    :visible-order="i + 1"
                    :show-parented-questions-always="true"
                    class="mb-16"
                    style="min-width: 320px;"
                />
                <div
                    v-if="hasAnyConditions"
                    class="w-1/3 pl-12 text-small text-neutral-800"
                    style="display: table-cell; vertical-align: middle;"
                >
                    <div
                        v-for="(cg, j) in element.condition_groups"
                        :key="j"
                    >
                        <b>{{ getConditionGroupActionText(cg) }}</b>
                        <ul class="pl-4">
                            <li
                                v-for="(c, h) in cg.conditions"
                                :key="h"
                                class="list-disc"
                            >
                                {{ getConditionText(c) }}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <disqualification-page
                v-for="(d, i) in disqualificationPages"
                :key="i"
                :element="d"
                class="pb-10"
                style="break-inside: avoid;"
            />
            <thank-you style="break-inside: avoid;" />
        </div>
        <filler-footer
            :has-own-privacy-policy="hasOwnPrivacyPolicy"
            :privacy-policy-company="privacyPolicyTitle"
            :privacy-policy-url="privacyPolicyUrl"
        />
    </div>
</template>

<script>
import {mapGetters, mapState} from 'vuex';
import striptags from 'striptags';
import SurveyElement from '@/components/Element/Element.vue';
import Intro from '@/components/Intro.vue';
import ThankYou from '@/components/ThankYou.vue';
import DisqualificationPage from '@/components/DisqualificationPage.vue';
import FillerFooter from '@/components/Footer.vue';
import {actionEnum, actionTextMap, connectiveTextMap, operationTextMap} from '@/enums/conditionEnum';
import VStyle from '@/components/ui/Style.vue';

export default {
    name: 'PrintFiller',
    components: {
        SurveyElement,Intro,ThankYou,DisqualificationPage,FillerFooter,
        VStyle,
    },
    computed: {
        ...mapGetters(['editableElements']),
        ...mapState([
            'title',
            'description',
            'background_color',
            'logo_file_url',
            'title_font_name',
            'title_font_weight',
            'answer_font_name',
            'answer_font_weight',
            'primary_color',
            'text_color',
            'is_filling_time_visible',
            'disqualificationPages',
            'hasOwnPrivacyPolicy',
            'privacyPolicyUrl',
            'urlParameters',
        ]),
        privacyPolicyTitle() {
            return this.$store.state.privacyPolicyTitle || 'survey creator';
        },
        hasAnyConditions() {
            return this.editableElements
                .reduce((r,e) => (e.condition_groups && e.condition_groups.length > 0) || r, false);
        },
    },
    mounted() {
        this.$load.stop();
    },
    methods: {
        getConditionGroupText(element) {
            return element.condition_groups
                .reduce((fullText, cg) => fullText + `${this.getConditionGroupActionText(cg)}${this.getConditionsText(cg)}`, '');
        },
        getConditionGroupActionText(cg) {
            let actionPayload = '';
            switch (cg.action) {
            case actionEnum.CHANGE:
                actionPayload = cg.action_payload;
                break;
            case actionEnum.JUMP:
                if (cg.action_payload === 'thank-you') {
                    actionPayload = this.$t('PRINT.THANK_YOU_PAGE_NAME');
                } else {
                    const elementToJump = this.editableElements.find(e => e.guid === cg.action_payload);
                    actionPayload = elementToJump ? striptags(elementToJump.title) : '';
                }
                break;
            default:
                break;
            }
            return `${actionTextMap[cg.action]()} ${actionPayload} ${this.$t('PRINT.CONDITION_CONJUCTION_IF')}:`;
        },
        getConditionText(c) {
            let conditionValuesString = '';

            const conditionalQuestion = this.editableElements.find(e => e.guid === c.conditional_question_guid);
            const conditionalParameter = this.urlParameters.find(parameter => parameter.guid === c.conditional_question_guid);
            if(!conditionalQuestion && !conditionalParameter) {
                return null;
            }
            const title = conditionalQuestion ? conditionalQuestion.title : conditionalParameter.name;

            if (c.value && c.value.value) {
                let values = [];
                // if its a 'one of, all off, etc'
                if (Array.isArray(c.value.value) && conditionalQuestion) {
                    for (let l = 0; l < c.value.value.length; l++) {
                        if (conditionalQuestion.ranges && conditionalQuestion.ranges.length > 0) {
                            if (conditionalQuestion.ranges[c.value.value[l]]) {
                                values.push(conditionalQuestion.ranges[c.value.value[l]].range);
                            }
                            else {
                                values.push(conditionalQuestion.ranges.find(r => r.id == c.value.value[l]).range);
                            }
                        } else {
                            values.push(conditionalQuestion.options[c.value.value[l]]);
                        }
                    }
                    conditionValuesString = values.map((v) => striptags(v)).join(', ');
                    conditionValuesString += c.value.dkna ? (conditionValuesString !== '' ? ', ' : '') + conditionalQuestion.dkna_option_label : '';
                    conditionValuesString += c.value.other ? (conditionValuesString !== '' ? ', ' : '') + conditionalQuestion.other_option_label : '';
                } else {
                    //it's the case of equality with a number
                    conditionValuesString = c.value.value;
                }
            }
            const connective = c.order !== 0 ? ` ${connectiveTextMap[c.connective]()} ` : '';
            return `${connective} ${striptags(title)} ${this.$t('PRINT.CONDITION_CONJUCTION_ANSWER')} ${operationTextMap[c.operation]()} ${conditionValuesString}`;
        }
    }
};
</script>
